import React, { useState } from "react";
import IconClaim from "../../assets/transaction/claim";
import IconCustomerSolutions from "../../assets/transaction/customerSolutions";
import ActionTypes from "../../store/actionTypes";
import { useCentralStore } from "../../store/appContext";
import { isEmpty } from "../../utils/functionUtils";

export default function ReportMenu({}) {
  const [{ selectedCategory, reportType }, dispatch] = useCentralStore();

  const handleClick = (category) => {
    dispatch({
      type: ActionTypes.SET_SELECTED_REPORT_TYPE,
      payload: category,
    });
  };

  let [over,setOver]= useState(false);
  let fill =  over ? '#FFFFFF' : '#CB5AFF'

  return (
    <>
    {isEmpty(selectedCategory) ?
      <div className="w-full pb-10 flex flex-col justify-center ">
      <div className="flex mt-5 ">
        <p className="text-4xl leading-54 md:leading-5 mt-10 font-light mx-auto">
          <b>Select from the options below</b>
        </p>
      </div>

      <div className="mt-16 center">
        <div className="flex flex-row justify-between items-center">
          <div onMouseOver={()=>(setOver(true))} onMouseOut={()=>setOver(false)}
            className={` w-48 flex flex-col justify-center items-center  border py-4  rounded-2xl  px-8 mr-7 cursor-pointer  
      transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125  duration-500 reportMenu`}
            onClick={() => handleClick("Inquiry")}
          >
            <p className={` text-center my-4 text-xl`}>Inquiry Report</p>
            <IconCustomerSolutions className="mx-auto" fill={reportType === "Inquiry" ? fill : "#CB5AFF"} />
          </div>{" "}
          <div onMouseOver={()=>(setOver(true))} onMouseOut={()=>setOver(false)}
            className={` w-52 flex flex-col justify-center items-center  border py-4  rounded-2xl  mr-7 cursor-pointer  
      transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125  duration-500 reportMenu`}
            onClick={() => handleClick("AI")}
          >
            <p className={`text-center my-4 text-xl`}>AI Report</p>
            <IconClaim className="mx-auto" fill={reportType === "AI" ? fill : "#CB5AFF"} />
          </div>{" "}
        </div>
      </div>
    </div>
    :
    <>

      </>
    }
    </>
  );
}