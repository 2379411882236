export const exportcolumns = [
    {
      title: 'WorkQueue Number',
      dataIndex: 'WorkQueueNbr',
      key: 'WorkQueueNbr',
      width: 280
    },
    {
      title: 'TradeQuoteNumber',
      dataIndex: 'TradeQuoteNumber',
      key: 'TradeQuoteNumber',
      width: 280
    },
    {
      title: 'MobileNumber',
      dataIndex: 'MobileDeviceNbr',
      key: 'MobileDeviceNbr',
      width: 280
    },
    {
      title: 'CallDriverCategory',
      dataIndex: 'CallDriverCategory',
      key: 'CallDriverCategory',
      width: 280
    },
    {
      title: 'CallDriverSubCategory',
      dataIndex: 'CallDriverSubCategory',
      key: 'CallDriverSubCategory',
      width: 280
    },
    {
      title: 'Default CallDriverCategory',
      dataIndex: 'DefaultCallDriverCategory',
      key: 'DefaultCallDriverCategory',
      width: 280
    },
    {
      title: 'Default CallDriverSubCategory',
      dataIndex: 'DefaultCallDriverSubCategory',
      key: 'DefaultCallDriverSubCategory',
      width: 280
    },
    {
      title: 'Received Date',
      dataIndex: 'WorkItemCreatedDate',
      key: 'WorkItemCreatedDate',
      width: 280
    },
    {
      title: 'Workqueue Status',
      dataIndex: 'WorkItemStatus',
      key: 'WorkItemStatus',
      width: 280
    },
    {
      title: 'Customer Inquiry',
      dataIndex: 'CustomerInquiry',
      key: 'CustomerInquiry',
      width: 280
    },
    {
      title: 'AI Summary',
      dataIndex: 'AISummary',
      key: 'AISummary',
      width: 280
    },
    {
      title: 'AI FeedBack',
      dataIndex: 'ResponseStatus',
      key: 'ResponseStatus',
      width: 280
    },
    {
      title: 'AI Response',
      dataIndex: 'AIResponse',
      key: 'AIResponse',
      width: 280
    },
    {
      title: 'Agent Response',
      dataIndex: 'AgentResponse',
      key: 'AgentResponse',
      width: 280
    },
    {
      title: 'Rejection Reason Code',
      dataIndex: 'RejectionType',
      key: 'RejectionType',
      width: 280
    },
    {
      title: 'Rejection Comments',
      dataIndex: 'AgentComments',
      key: 'AgentComments',
      width: 280
    },
    {
      title: 'Resolution Time (Hour)',
      dataIndex: 'ResolutionTimeInHour',
      key: 'ResolutionTimeInHour',
      width: 280
    }
  ];

export const columns = [
    {
      title: 'WorkQueue Number',
      dataIndex: 'WorkQueueNbr',
      key: 'WorkQueueNbr',
    },
    {
      title: 'TradeQuoteNumber',
      dataIndex: 'TradeQuoteNumber',
      key: 'TradeQuoteNumber',
    },
    {
      title: 'MobileNumber',
      dataIndex: 'MobileDeviceNbr',
      key: 'MobileDeviceNbr'
    },
    {
      title: 'CallDriverCategory',
      dataIndex: 'CallDriverCategory',
      key: 'CallDriverCategory',
    },
    {
      title: 'CallDriverSubCategory',
      dataIndex: 'CallDriverSubCategory',
      key: 'CallDriverSubCategory',
    },
    {
      title: 'Default CallDriverCategory',
      dataIndex: 'DefaultCallDriverCategory',
      key: 'DefaultCallDriverCategory',
    },
    {
      title: 'Default CallDriverSubCategory',
      dataIndex: 'DefaultCallDriverSubCategory',
      key: 'DefaultCallDriverSubCategory',
    },
    {
      title: 'Received Date',
      dataIndex: 'WorkItemCreatedDate',
      key: 'WorkItemCreatedDate',
    },
    {
      title: 'Workqueue Status',
      dataIndex: 'WorkItemStatus',
      key: 'WorkItemStatus',
    },
    {
      title: 'AI FeedBack',
      dataIndex: 'ResponseStatus',
      key: 'ResponseStatus',
    },
    {
      title: 'Resolution Time (Hour)',
      dataIndex: 'ResolutionTimeInHour',
      key: 'ResolutionTimeInHour'
    }
  ];

  export const rejectionReason = [
    { name: 'WRONG CALL DRIVER', value: 'WRONG CALL DRIVER' },
    { name: 'WRONG CALL SUB-DRIVER', value: 'WRONG CALL SUB-DRIVER' },
    { name: 'WRONG AI RESPONSE', value: 'WRONG AI RESPONSE' },
    { name: 'HALLUCINATION', value: 'HALLUCINATION' },
    { name: 'BUSINESS RULE ISSUE', value: 'BUSINESS RULE ISSUE' },
    { name: 'INCOMPLETE RESPONSE', value: 'INCOMPLETE RESPONSE' }
]

export const status = [{name:"CLOSED", value:"CLOSED"},{name:"IN-PROGRESS", value:"IN-PROGRESS"},{name:"OPEN", value:"OPEN"}]

export const feedback = [{name:"APPROVED", value:"APPROVED"},{name:"REJECTED", value:"REJECTED"},{name:"EDITED", value:"EDITED"}]

export const programTypes = [
  { name: 'TRADEIN', value: 'TRADEIN' },
  { name: 'DP', value: 'DP' }
]

export const AIClients = [
  { name: 'SAMSUNG', value: 'SAMSUNG' },
  { name: 'JBHIFI', value: 'JBHIFI' }
]

export const ProgramClientMapping = [
  { program: 'TRADEIN', clients: [ { name: 'SAMSUNG', value: 'SAMSUNG' }] },
  { program: 'DP', clients: [{ name: 'JBHIFI', value: 'JBHIFI' }] }
]

