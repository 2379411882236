const ActionTypes = {
 SET_LOADING: 'SET_LOADING',
 SET_PROJECT: 'SET_PROJECT',
 SELECTED_CLIENT: 'SELECTED_CLIENT',
 SELECTED_STORE: 'SELECTED_STORE',
 SELECTED_DASHBOARD_DATE: 'SELECTED_DASHBOARD_DATE',

  // APIs
  GET_CLIENTS: "GET_CLIENTS",
  GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
  GET_CLIENTS_FAILURE: "GET_CLIENTS_FAILURE",

  GET_DASHBOARD_DETAILS: "GET_DASHBOARD_DETAILS",
  GET_DASHBOARD_DETAILS_SUCCESS: "GET_DASHBOARD_DETAILS_SUCCESS",
  GET_DASHBOARD_DETAILS_FAILURE: "GET_DASHBOARD_DETAILS_FAILURE",

  GENERATE_TOKEN: "GENERATE_TOKEN",

  GET_SUBMATRICS_DETAILS: "GET_SUBMATRICS_DETAILS",
  GET_SUBMATRICS_DETAILS_SUCCESS: "GET_SUBMATRICS_DETAILS_SUCCESS",
  GET_SUBMATRICS_DETAILS_FAILURE: "GET_SUBMATRICS_DETAILS_FAILURE",

  EXPORT_SUBMATRICS_DETAILS_SUCCESS: "EXPORT_SUBMATRICS_DETAILS_SUCCESS",
  EXPORT_SUBMATRICS_DETAILS_FAILURE: "EXPORT_SUBMATRICS_DETAILS_FAILURE",

  SET_USER_LOGGED_IN: "SET_USER_LOGGED_IN",
  SET_AUTH_CODE_SEARCH: "SET_AUTH_CODE_SEARCH",
  SET_USER_DATA: "SET_USER_DATA",
  SET_USER_DATA_NEW: "SET_USER_DATA_NEW",

  SET_LOADING_DATA: "SET_LOADING_DATA",
  SET_REFRESHED_ON: "SET_REFRESHED_ON",

  GET_ROLE_BASES_MATRICS: "GET_ROLE_BASES_MATRICS",
  GET_ROLE_BASES_MATRICS_SUCCESS: "GET_ROLE_BASES_MATRICS_SUCCESS",
  GET_ROLE_BASES_MATRICS_FAILURE: "GET_ROLE_BASES_MATRICS_FAILURE",

  SET_API_ERROR: "SET_API_ERROR",
  POST_LOGGER: "POST_LOGGER",

  SET_SELECTED_TAB: "SET_SELECTED_TAB",
  SET_MAP_SELECTED: "SET_MAP_SELECTED",
  SET_PROGRAM_NAME: "SET_PROGRAM_NAME",
  SET_LOADING_PER: "SET_LOADING_PER",
  ADD_FAVOURITE: "ADD_FAVOURITE",
  SET_FULLSCREEN: "SET_FULLSCREEN",

  GET_CHILD_SUBMATRICS: "GET_CHILD_SUBMATRICS",
  GET_CHILD_SUBMATRICS_SUCCESS: "GET_CHILD_SUBMATRICS_SUCCESS",
  GET_CHILD_SUBMATRICS_FAILURE: "GET_CHILD_SUBMATRICS_FAILURE",

  SET_DASHBOARD_LOADING: "SET_DASHBOARD_LOADING",
  SET_PROJECT_NAME:"SET_PROJECT_NAME",

  SET_CATEGORY: "SET_CATEGORY",
  EXPAND_TRADEIN_DASHBOARD_OPTIONS: "EXPAND_TRADEIN_DASHBOARD_OPTIONS",
  EXPAND_TRANSACTIONAL_OPTIONS: "EXPAND_TRANSACTIONAL_OPTIONS",

  SET_COUNTRYDATA: "SET_COUNTRYDATA",
  SET_UNIQUE_CLIENTS: "SET_UNIQUE_CLIENTS",
  SET_PROGRAM_DATA: "SET_PROGRAM_DATA",
  SET_UNIQUE_CLIENS_DATA: "SET_UNIQUE_CLIENS_DATA",
  SET_ALL_DASHBOARDS: "SET_ALL_DASHBOARDS",

  GET_TRANSACTION_REPORTS: "GET_TRANSACTION_REPORTS",
  GET_TRANSACTION_REPORTS_SUCCESS: "GET_TRANSACTION_REPORTS_SUCCESS",
  GET_TRANSACTION_REPORTS_FAILURE: "GET_TRANSACTION_REPORTS_FAILURE",

  SET_FAV_ARRAY_CNT: "SET_FAV_ARRAY_CNT",
  SET_FAV_ARRAY: "SET_FAV_ARRAY",
  SET_SELECTED_FAV_DATA: "SET_SELECTED_FAV_DATA",
  SET_STORES_LIST:"SET_STORES_LIST",
  SET_SITE_MAP:"SET_SITE_MAP",
  SET_REMOVED_SUBMATRICS_DATA: "SET_REMOVED_SUBMATRICS_DATA",
  SET_UPDATED_SUBMATRICS_DATA:"SET_UPDATED_SUBMATRICS_DATA",
  GET_DASHBOARD_DETAILS_DATA_SUCCESS:"GET_DASHBOARD_DETAILS_DATA_SUCCESS",

  GET_DASHBOARD_DETAILS_SUCCESS_90_DAYS: "GET_DASHBOARD_DETAILS_SUCCESS_90_DAYS",

  UPLOAD_BILLING_REVENUE: 'BILLING_REVENUE_UPLOAD',
  UPDATE_BILLING_REVENUE: 'UPDATE_BILLING_REVENUE',
  GET_BILLING_REVENUE_FILES: 'GET_BILLING_REVENUE_FILES',

  UPLOAD_FILE_ERROR: 'UPLOAD_FILE_ERROR',
  FILE_UPLOADER_SUCCESS: 'FILE_UPLOADER_SUCCESS',
  FILE_UPLOADER_FAILURE: 'FILE_UPLOADER_FAILURE',
  FILE_UPLOADER_REQUEST: 'FILE_UPLOADER_REQUEST',
  SELECTED_FILE: 'SELECTED_FILE',
  SELECTED_FILE_TYPE: 'SELECTED_FILE_TYPE',
  SELECTED_REVENUE_CLIENT: 'SELECTED_REVENUE_CLIENT',
  SELECTED_REVENUE_MONTH: 'SELECTED_REVENUE_MONTH',
  SELECTED_REVENUE_YEAR: 'SELECTED_REVENUE_YEAR',
  RESET_REVENUE_STATE: 'RESET_REVENUE_STATE',
  SELECTED_ACCOUNTING_MONTH: 'SELECTED_ACCOUNTING_MONTH',
  SELECTED_RECONCILIATION_CLIENT: 'SELECTED_RECONCILIATION_CLIENT',

  GET_M1_RECONCILE_SUMMARY: "GET_M1_RECONCILE_SUMMARY",
  GET_M1_BILLING_RECONCILE_DATA: "GET_M1_BILLING_RECONCILE_DATA",
  GET_M1_BILLING_RECONCILE_SUMMARY_DATA_REQUEST: "GET_M1_BILLING_RECONCILE_SUMMARY_DATA_REQUEST",

  UPLOAD_FILE_CLICK : "UPLOAD_FILE_CLICK",
  SET_SELECTED_DASHBOARD: "SET_SELECTED_DASHBOARD",
  GO_TO_MAPS_PAGE: "GO_TO_MAPS_PAGE",

  SGP_SET_REPORT_NAME: "SGP_SET_REPORT_NAME",
  SGP_SET_REPORT_FILTER: "SGP_SET_REPORT_FILTER",
  SGP_SET_REPORT_FILTER_DURATION: "SGP_SET_REPORT_FILTER_DURATION",
  
  GET_STARHUB_REPORT_COUNT: "GET_STARHUB_REPORT_COUNT",
  GET_STARHUB_REPORT_COUNT_SUCCESS: "GET_STARHUB_REPORT_COUNT_SUCCESS",
  GET_STARHUB_REPORT_COUNT_FAILURE: "GET_STARHUB_REPORT_COUNT_FAILURE",

  GET_STARHUB_REPORT_COUNT_DETAILS: "GET_STARHUB_REPORT_COUNT_DETAILS",
  GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS: "GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS",
  GET_STARHUB_REPORT_COUNT_DETAILS_FAILURE: "GET_STARHUB_REPORT_COUNT_DETAILS_FAILURE",

  GET_STARHUB_REPORT_DETAILS: "GET_STARHUB_REPORT_DETAILS",
  GET_STARHUB_REPORT_DETAILS_SUCCESS: "GET_STARHUB_REPORT_DETAILS_SUCCESS",
  GET_STARHUB_REPORT_DETAILS_FAILURE: "GET_STARHUB_REPORT_DETAILS_FAILURE",

  HIDE_DATE_FILTER: "HIDE_DATE_FILTER",
  SET_USER_DETAILS: "SET_USER_DETAILS",

  GET_CHART_COUNT_DATA: "GET_CHART_COUNT_DATA",
  GET_CHART_COUNT_DATA_SUCCESS: "GET_CHART_COUNT_DATA_SUCCESS",
  GET_CHART_COUNT_DATA_FAILURE: "GET_CHART_COUNT_DATA_FAILURE",
  SET_EMAIL_ADDRESS: "SET_EMAIL_ADDRESS",
  SET_PASSWORD: "SET_PASSWORD",
  GET_SLA_REPORTS_DETAILS: "GET_SLA_REPORTS_DETAILS",
  GET_SLA_REPORTS_DETAILS_SUCCESS: "GET_SLA_REPORTS_DETAILS_SUCCESS",
  GET_SLA_REPORTS_DETAILS_FAILURE: "GET_SLA_REPORTS_DETAILS_FAILURE",
  SET_PROJECT_SUB_MENU: "SET_PROJECT_SUB_MENU",
  SGP_SET_DOWNLOAD_REPORT_DURATION_LABEL: "SGP_SET_DOWNLOAD_REPORT_DURATION_LABEL",
  SGP_SET_DOWNLOAD_REPORT_DURATION: "SGP_SET_DOWNLOAD_REPORT_DURATION",
  SGP_DOWNLOAD_REPORT: "SGP_DOWNLOAD_REPORT",
  VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  SIGNOUT_REQUEST: "SIGNOUT_REQUEST",

  GET_SS_REPORT_COUNT: "GET_SS_REPORT_COUNT",
  GET_SS_REPORT_COUNT_SUCCESS: "GET_SS_REPORT_COUNT_SUCCESS",
  GET_SS_REPORT_COUNT_FAILURE: "GET_SS_REPORT_COUNT_FAILURE",

  GET_SS_REPORT_DETAILS: "GET_SS_REPORT_DETAILS",
  GET_SS_REPORT_DETAILS_SUCCESS: "GET_SS_REPORT_DETAILS_SUCCESS",
  GET_SS_REPORT_DETAILS_FAILURE: "GET_SS_REPORT_DETAILS_FAILURE",

  SS_SET_REPORT_NAME: "SS_SET_REPORT_NAME",
  SS_SET_REPORT_FILTER_DURATION: "SS_SET_REPORT_FILTER_DURATION",
  SS_SET_REPORT_FILTER: "SS_SET_REPORT_FILTER",

  GET_SCORECARD_SUMMARY: "GET_SCORECARD_SUMMARY",
  GET_SCORECARD_SUMMARY_SUCCESS: "GET_SCORECARD_SUMMARY_SUCCESS",
  GET_SCORECARD_SUMMARY_FAILURE: "GET_SCORECARD_SUMMARY_FAILURE",

  GET_PRM_EXPORT_DATA: "GET_PRM_EXPORT_DATA",
  GET_PRM_EXPORT_DATA_SUCCESS: "GET_PRM_EXPORT_DATA_SUCCESS",
  GET_PRM_EXPORT_DATA_FAILURE: "GET_PRM_EXPORT_DATA",

  GET_INQUIRY_DATA: "GET_INQUIRY_DATA",
  GET_INQUIRY_DATA_SUCCESS: "GET_INQUIRY_DATA_SUCCESS",
  GET_INQUIRY_DATA_FAILURE: "GET_INQUIRY_DATA_FAILURE",

  GET_EXPORT_INQUIRY_DATA: "GET_EXPORT_INQUIRY_DATA",
  GET_EXPORT_INQUIRY_DATA_SUCCESS: "GET_EXPORT_INQUIRY_DATA_SUCCESS",
  GET_EXPORT_INQUIRY_DATA_FAILURE: "GET_EXPORT_INQUIRY_DATA_FAILURE",

  SET_START_DATE: "SET_START_DATE",
  SET_END_DATE: "SET_END_DATE",

  GET_INQUIRY_AI_RESPONSE: "GET_INQUIRY_AI_RESPONSE",
  GET_INQUIRY_AI_RESPONSE_SUCCESS: "GET_INQUIRY_AI_RESPONSE_SUCCESS",
  GET_INQUIRY_AI_RESPONSE_FAILURE: "GET_INQUIRY_AI_RESPONSE_FAILURE",

  GET_INQUIRY_AI_GRAPH_RESPONSE: "GET_INQUIRY_AI_GRAPH_RESPONSE",
  GET_INQUIRY_AI_GRAPH_RESPONSE_SUCCESS: "GET_INQUIRY_AI_GRAPH_RESPONSE_SUCCESS",
  GET_INQUIRY_AI_GRAPH_RESPONSE_FAILURE: "GET_INQUIRY_AI_GRAPH_RESPONSE_FAILURE",

  VERIFY_JWT: "VERIFY_JWT",
  VERIFY_JWT_SUCCESS: "VERIFY_JWT_SUCCESS",
  VERIFY_JWT_FAILURE: "VERIFY_JWT_FAILURE",

  GET_CELCOM_RECONCILE_SUMMARY: "GET_CELCOM_RECONCILE_SUMMARY",
  GET_CELCOM_BILLING_RECONCILE_DATA: "GET_CELCOM_BILLING_RECONCILE_DATA",
  GET_CELCOM_BILLING_RECONCILE_DATA_SUCCESS: "GET_CELCOM_BILLING_RECONCILE_DATA_SUCCESS",
  GET_CELCOM_BILLING_RECONCILE_DATA_FAILURE: "GET_CELCOM_BILLING_RECONCILE_DATA_FAILURE",
  GET_CELCOM_BILLING_RECONCILE_SUMMARY_DATA_REQUEST: "GET_CELCOM_BILLING_RECONCILE_SUMMARY_DATA_REQUEST",

  SET_SELECTED_REPORT_TYPE: 'SET_SELECTED_REPORT_TYPE',

  
  GET_AI_PERFORMANCE_REPORT: "GET_AI_PERFORMANCE_REPORT",
  GET_AI_PERFORMANCE_REPORT_SUCCESS: "GET_AI_PERFORMANCE_REPORT_SUCCESS",
  GET_AI_PERFORMANCE_REPORT_FAILURE: "GET_AI_PERFORMANCE_REPORT_FAILURE",

  SET_AI_REPORT_FILTER_DURATION: 'SET_AI_REPORT_FILTER_DURATION',
  SET_AI_REPORT_PROGRAM_TYPE: 'SET_AI_REPORT_PROGRAM_TYPE',
  SET_AI_REPORT_CLIENT_NAME: 'SET_AI_REPORT_CLIENT_NAME'

};

export default ActionTypes;