import React, { useEffect, useRef, useState } from "react";
import { Table  } from "antd";
import { getColumnSearchProps } from "./AntTableSearchFilter";
import CrossIcon from "../../../assets/icons/cross-icon";
import API from "../../../api";
import moment from "moment";
import ActionTypes from "../../../store/actionTypes";
import { useCentralStore } from "../../../store/appContext";
import Button from "../button/button";
import exportToCSVInventory from "../hepler/export-to-csv-submatricsData";
import Popup from "../popup/popup";

const DataTable = ({
  label,
  value,
  onClose,
  submatricsDetailsData,
  selectedDate,
  subquerykey,
  MetricsLabel,
  resources,
  childSubmetrics90DaysData,
  isSubmetrics = false,
  childSubmetrics90DaysDataExport
}) => {
  const inputRef = useRef(null);
  const resetRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [column, setColumn] = useState("");
  // const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [
    {
      exportSubmatricsData,
      projectData,
      nintyDaysData,
      selectedStore,
      selectedClient,
      clientData,
      userData,
      userDetails,
      projectDataFor90Days,
      selectedDashboard,
      selectedTab
    },
    dispatch,
  ] = useCentralStore();
  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
  };
  let tableColumns = [];
  const totalKeys = {};

  submatricsDetailsData.reduce((acc, current) => {
    const keys = Object.keys(current);
    for (var i in keys) {
      totalKeys[keys[i]] = 1;
    }
  }, totalKeys);

  // const NintyDaysCount = nintyDaysData?.Metrics?.find((e) => e.label === MetricsLabel)?.submetrics?.find((e) => e.label === subquerykey)?.value 
  // console.log("NintyDaysCount", NintyDaysCount)
  var keys = Object.keys(totalKeys);
  for (var i in keys) {
    tableColumns.push({
      title: keys[i]?.replace(/([a-z0-9])([A-Z])/g, "$1 $2")?.toUpperCase(),
      dataIndex: keys[i],
      key: keys[i],
      width: 280,
      // ...getColumnSearchProps(keys[i],
      //         inputRef,
      //         resetRef,
      //         searchText,
      //         setSearchText,
      //         column,
      //         setColumn)
    });
  }

  const getPartsColumns = () => {
    let headerColumns = [];
    tableColumns.forEach((c, i) => {
      if (i < 5) {
        headerColumns.push({
          ...c,
          ...getColumnSearchProps(
            c.key,
            inputRef,
            resetRef,
            searchText,
            setSearchText,
            column,
            setColumn
          ),
        });
      } else {
        headerColumns.push({
          ...c,
        });
      }
      // headerColumns.push({
      //     ...c,
      //     ...getColumnSearchProps(
      //       c.key,
      //       inputRef,
      //       resetRef,
      //       searchText,
      //       setSearchText,
      //       column,
      //       setColumn
      //     ),
      //   });
    });
    return headerColumns;
  };

  const [exportErrMsg, setExportErrMsg] = useState(null);

  const exportData = async () => {
    setExportErrMsg(null)
    let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
    const payload = {
      // StartDate: moment(last60Days).format(),
      StartDate: projectData?.Duration?.StartDate,
      EndDate: projectData?.Duration?.EndDate,
      QueryKey: subquerykey,
      Channel:
        selectedStore?.toUpperCase() === "STORE" ? "IN-STORE" : selectedStore,
      isExport: true,
      Email: userDetails?.email,
      TotalCount: isSubmetrics ? Math.abs(Math.ceil(childSubmetrics90DaysDataExport))?.toString() : Math.abs(Math.ceil(value))?.toString(),
      MetricsLabel: MetricsLabel,
      Client: getClientName?.Client,
      // Duration: resources.LAST_60_DAYS_VALUE
      Duration: projectData?.Duration?.Duration,
      CreatedBy: ((selectedTab === "Trade-In BPM Performance" || selectedTab === "Trade-In Operational Performance") ? "oneopsdashboardsyncup" : (selectedTab === "Harvey_Norman BPM Performance" || selectedTab === "Harvey_Norman Operational Performance" || selectedTab === "Samsung BPM Performance" || selectedTab === "Samsung Operational Performance" || selectedTab === "Telstra BPM Performance" || selectedTab === "Telstra Operational Performance" ) ? "DP_harvey_norman_SETUP" : selectedTab === "Home Plus BPM Performance" ? "HOME_PLUS_SETUP" : "DP_StarHub_SETUP")
    };
    setLoading(true);
    API[ActionTypes.GET_SUBMATRICS_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          if (Math.abs(Math.ceil(value)) <= 1000) {
            exportToCSVInventory(
              tableColumns,
              data?.GetSubMetricsDetailsResponse,
              "",
              "",
              label,
              dispatch,
              ActionTypes
            );
            // setRefreshedOn(moment().format("YYYY-MM-DD HH:mm:ss"));
            dispatch({
              type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
              payload: data?.GetSubMetricsDetailsResponse,
            });
          } else {
            dispatch({
              type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
              payload: data,
            });
            setEmailPopup(true)
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
          payload: err,
        });
        setExportErrMsg('Error occurred while exporting data...')
        // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: payload,
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
    //   .finally(() => {
    //     setLoading(false);
    //     setFirstLoad(false);
    //   });
    // exportToCSVInventory(tableColumns,currentSearchedData || exportSubmatricsData, "", "");
  };

  const _submatricsDetailsData = function (submatricsDetailsData) {
      return submatricsDetailsData.map((obj) => {
        let dateObj = {};
        Object.entries(obj).map(
          ([key, value]) =>
            (dateObj[key] = key?.toUpperCase()?.includes("_DATE")
              ? value?.length !== 0
                ? (MetricsLabel?.toLowerCase() === 'device registration' || MetricsLabel?.toLowerCase() === 'enrolment') ? moment(value).format("DD-MM-YYYY") : moment(value).format("DD-MM-YYYY HH:mm:ss")
                : null
              : value)
        );
        return dateObj;
      });
  };

  // const [page, setPage] = useState({ current: 1, pageSize: 10 });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [firstLoad, setFirstLoad] = useState(true);
  const [emailPopup, setEmailPopup] = useState(false);

  const onPerEntryPage = (e) => {
    setExportErrMsg(null)
    setFirstLoad(false)
    setPagination({
      current: 1,
      // pageSize: e.target.value > value || e.target.value == '' ? pagination.pageSize : e.target.value
      pageSize: e.target.value
    })
  };

  const onPageChange = (e) => {
    setExportErrMsg(null)
    setFirstLoad(false)
    setPagination({
      current: (e.target.value > Math.ceil(value/pagination.pageSize)) || e.target.value == '' ? pagination.current : e.target.value,
      pageSize: pagination.pageSize
    })
  };

  
  const [currentSearchedData, setCurrentSearchedData] = useState(undefined);

  const handleTableChange = (currentPagination, filters, sorter, extra) => {
    dispatch({
      type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
      payload: undefined,
    });
    let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
    const NintyDaysCount = nintyDaysData?.Metrics?.find((e) => e.label === MetricsLabel)?.submetrics?.find((e) => e.label === subquerykey)?.value
    const payload = {
      StartDate: selectedDate === "LAST_30_DAYS" || selectedDate === "LAST_60_DAYS" ? projectData?.Duration?.StartDate : nintyDaysData?.Duration?.StartDate,
      EndDate: selectedDate === "LAST_30_DAYS" || selectedDate === "LAST_60_DAYS" ? projectData?.Duration?.EndDate : nintyDaysData?.Duration?.EndDate,
      QueryKey: subquerykey,
      StartingRow: 1,
      RowsCount: Number(pagination.pageSize),
      Channel:
        selectedStore?.toUpperCase() === "STORE" ? "IN-STORE" : selectedStore,
      TotalCount: selectedDate === "LAST_30_DAYS" || selectedDate === "LAST_60_DAYS" ? value.toString() : NintyDaysCount?.toString(),
      MetricsLabel: MetricsLabel,
      Client: getClientName?.Client,
      CreatedBy: ((selectedTab === "Trade-In BPM Performance" || selectedTab === "Trade-In Operational Performance") ? "oneopsdashboardsyncup" : (selectedTab === "Harvey_Norman BPM Performance" || selectedTab === "Harvey_Norman Operational Performance" || selectedTab === "Samsung BPM Performance" || selectedTab === "Samsung Operational Performance" || selectedTab === "Telstra BPM Performance" || selectedTab === "Telstra Operational Performance") ? "DP_harvey_norman_SETUP" : selectedTab === "Home Plus BPM Performance" ? "HOME_PLUS_SETUP" : "DP_StarHub_SETUP")
    };
    setLoading(true);
    API[ActionTypes.GET_SUBMATRICS_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          // setRefreshedOn(moment().format("YYYY-MM-DD HH:mm:ss"));
          dispatch({
            type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
            payload: data?.GetSubMetricsDetailsResponse,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
          payload: err,
        });
        // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: payload,
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
    //   .finally(() => {
    //     setLoading(false);
    //     setFirstLoad(false);
    //   });

    let isFilter = false;
    for (let objKey in filters) {
      setCurrentSearchedData(extra?.currentDataSource);

      if (filters[objKey] && filters[objKey].length) {
        isFilter = true;
        const totalCount = currentSearchedData?.filter(
          (element) => element.objKey === filters[objKey][0]
        ).length;
        setPagination({
          ...pagination,
          current: 1,
          pageSize: pagination.pageSize,
          total: totalCount,
        });
      }
    }
    if (isFilter === false) {
      setCurrentSearchedData(undefined);
      setPagination({
        ...pagination,
        current: Number(pagination.current),
        pageSize: Number(pagination.pageSize),
        total: currentSearchedData?.length,
      });
    }
  };

  const [dataCount, setNintyDaysCount] = useState(0)
  const [dataCountExport, setDataCountExport] = useState(undefined)

  useEffect(() => {
    if(submatricsDetailsData || childSubmetrics90DaysData){
      setExportErrMsg(null)
      const NintyDaysCount = projectDataFor90Days?.Metrics?.find((e) => e.label === MetricsLabel)?.submetrics?.find((e) => e.label === subquerykey)?.value
      if(childSubmetrics90DaysData && isSubmetrics){
        setDataCountExport(Math.abs(childSubmetrics90DaysData))
      }if(NintyDaysCount === undefined){
        setNintyDaysCount(Math.abs(value))
      }else{
        setNintyDaysCount(Math.abs(NintyDaysCount))
      }
    }
  }, [submatricsDetailsData, childSubmetrics90DaysData])

  useEffect(()=>{
    if(pagination && firstLoad !== true){
      setExportErrMsg(null)
      dispatch({
        type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
        payload: undefined,
      });
      let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
      let last60Days = moment().subtract(60, 'days').calendar()
      // const NintyDaysCount = projectDataFor90Days?.Metrics?.find((e) => e.label === MetricsLabel)?.submetrics?.find((e) => e.label === subquerykey)?.value
      // setNintyDaysCount(NintyDaysCount)
      const payload = {
        // StartDate: moment(last60Days).format(),
        StartDate: projectDataFor90Days?.Duration?.StartDate,
        EndDate: projectDataFor90Days?.Duration?.EndDate,
        QueryKey: subquerykey,
        StartingRow: Number(pagination.current),
        RowsCount: Number(pagination.pageSize),
        Channel:
          selectedStore?.toUpperCase() === "STORE" ? "IN-STORE" : selectedStore,
        TotalCount: selectedDate === "LAST_30_DAYS" || selectedDate === "LAST_60_DAYS" ? dataCountExport?.toString() || value?.toString() : dataCount?.toString(),
        MetricsLabel: MetricsLabel,
        Client: getClientName?.Client,
        // Duration: resources.LAST_60_DAYS_VALUE,
        Duration: projectDataFor90Days?.Duration?.Duration,
        CreatedBy: ((selectedTab === "Trade-In BPM Performance" || selectedTab === "Trade-In Operational Performance") ? "oneopsdashboardsyncup" : (selectedTab === "Harvey_Norman BPM Performance" || selectedTab === "Harvey_Norman Operational Performance" || selectedTab === "Samsung BPM Performance" || selectedTab === "Samsung Operational Performance" || selectedTab === "Telstra BPM Performance" || selectedTab === "Telstra Operational Performance") ? "DP_harvey_norman_SETUP" : selectedTab === "Home Plus BPM Performance" ? "HOME_PLUS_SETUP" : "DP_StarHub_SETUP")
      };
      setLoading(true);
      API[ActionTypes.GET_SUBMATRICS_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
        .then(({ data }) => {
          setLoading(false);
          if (data) {
            // setRefreshedOn(moment().format("YYYY-MM-DD HH:mm:ss"));
            dispatch({
              type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
              payload: data?.GetSubMetricsDetailsResponse,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch({
            type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
            payload: err,
          });
          // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
          let _payload = {
            level: resources.POST_LOGGER_ERROR,
            category: resources.POST_LOGGER_API,
            ApiName: resources.POST_LOGGER_APINAME,
            message: {
              error: err,
              info: payload,
            },
          };
          API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);

          let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
          let last60Days = moment().subtract(60, 'days').calendar()

          const payloadEmail = {
            // StartDate: moment(last60Days).format(),
            StartDate: projectData?.Duration?.StartDate,
            EndDate: projectData?.Duration?.EndDate,
            QueryKey: subquerykey,
            Channel:
              selectedStore?.toUpperCase() === "STORE" ? "IN-STORE" : selectedStore,
            isExport: true,
            Email: userDetails?.email,
            TotalCount: value?.toString(),
            MetricsLabel: MetricsLabel,
            Client: getClientName?.Client,
            // Duration: resources.LAST_60_DAYS_VALUE
            Duration: projectData?.Duration?.Duration,
            isFailureScenario: true,
            CreatedBy: ((selectedTab === "Trade-In BPM Performance" || selectedTab === "Trade-In Operational Performance") ? "oneopsdashboardsyncup" : (selectedTab === "Harvey_Norman BPM Performance" || selectedTab === "Harvey_Norman Operational Performance" || selectedTab === "Samsung BPM Performance" || selectedTab === "Samsung Operational Performance" || selectedTab === "Telstra BPM Performance" || selectedTab === "Telstra Operational Performance") ? "DP_harvey_norman_SETUP" : selectedTab === "Home Plus BPM Performance" ? "HOME_PLUS_SETUP" : "DP_StarHub_SETUP")
          };
          setLoading(true);
          API[ActionTypes.GET_SUBMATRICS_DETAILS](payloadEmail, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
            .then(({ data }) => {
              setLoading(false);
              if (data) {
                // if (value <= 1000) {
                //   exportToCSVInventory(
                //     tableColumns,
                //     data?.GetSubMetricsDetailsResponse,
                //     "",
                //     "",
                //     label
                //   );
                //   // setRefreshedOn(moment().format("YYYY-MM-DD HH:mm:ss"));
                //   dispatch({
                //     type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
                //     payload: data?.GetSubMetricsDetailsResponse,
                //   });
                // } else {
                  dispatch({
                    type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
                    payload: data,
                  });
                // }
              }
            })
            .catch((err) => {
              setLoading(false);
              dispatch({
                type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
                payload: err,
              });
              dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
              let _payload = {
                level: resources.POST_LOGGER_ERROR,
                category: resources.POST_LOGGER_API,
                ApiName: resources.POST_LOGGER_APINAME,
                message: {
                  error: err,
                  info: payloadEmail,
                },
              };
              API[ActionTypes.POST_LOGGER](payloadEmail, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
            });
        });
    }
  }, [pagination, firstLoad])

  // console.log('count', dataCountExport, dataCount, value);
  // console.log('lengths', submatricsDetailsData?.length, dataCount, value);

  return (
    <div className="py-3">
      <div className="flex flex-row justify-between items-center mx-7 mb-3">
      {/* <div
        className={`flex flex-row ${
          exportSubmatricsData?.Message ? "justify-between" : "justify-end"
        } items-center`}
      > */}
        <p className="text-black text-lg font-bold">{label?.toUpperCase()}</p>
        <div>
          {(submatricsDetailsData?.length > 0 && (dataCountExport > 0 || dataCount > 0)  && (selectedDate == resources.SINCE_LAUNCH_VALUE || selectedDate == resources.THIS_YEAR_VALUE)) && <div className="text-black font-bold text-sm">Below data shown is as per the last 90 days records. For complete data please click Export All.</div>}
          {(submatricsDetailsData?.length === 0 && (dataCount > 0 || value > 0) && (selectedDate == resources.SINCE_LAUNCH_VALUE || selectedDate == resources.THIS_YEAR_VALUE)) && 
            <div className="text-black font-bold text-xs">Data fetched for last 90 days records. For complete data please click Export All.</div>
          }
          {exportErrMsg !== null &&
            <p className="text-black text-sm">{exportErrMsg}</p>
          }
          {exportSubmatricsData?.Message && (
            <>
              {/* <p className="text-card-greenBorder">
                {resources.EMAIL_SEND_MSG}
              </p> */}
              <Popup
                show={emailPopup}
                global={true}
                fitContent={true}
                emailPopup={true}
                // onClose={() => setEmailPopup(false)}
              >
                <div className="p-4 pb-6 border-4 border-app-purple rounded-md" style={{height: "auto !important"}}>
                <div className="flex justify-center bg-app-purple px-3 pt-6px pb-6px items-center font-bold rounded-lg -mt-9 w-20 text-center"><p className="text-white">Alert!</p></div>
                  <div className="flex justify-end">
                    <CrossIcon className="cursor-pointer" onClick={() => setEmailPopup(false)} />
                  </div>
                  <p className="text-app-purple font-semibold inline-block mt-2 px-4">
                    {resources.EMAIL_ALERT_MSG}
                    <br />
                    <p className="text-sm text-center">{resources.EMAIL_ALERT_CLOSE_MSG}</p>
                  </p>
                </div>
              </Popup>
            </>
          )}
        </div>        
        <div className="flex flex-row justify-end items-center">
          {(value > 0 || dataCount > 0 || dataCountExport > 0) && (
            <Button
              label={resources.EXPORT_ALL}
              className="bg-purple-color text-sm px-5 py-1 rounded-3xl mr-4 bg-app-purple text-white hover:text-slate-300"
              onClick={() => exportData()}
            />
          )}
          <CrossIcon
            className="float-right m-auto cursor-pointer"
            fill="black"
            onClick={onClose}
          />
        </div>
      </div>
      {/* {value > 0 && <p className="px-7 pb-3 text-black text-xs">{resources.DATA_TABLE_MSG}</p>} */}
      <Table
        //   rowSelection={rowSelection}
        columns={getPartsColumns()}
        dataSource={currentSearchedData || _submatricsDetailsData(submatricsDetailsData)}
        // pagination={{ show: true, total: submatricsDetailsData.length }}
        // pagination={{
        //   show: true,
        //   total: submatricsDetailsData?.length > 0 ? Math.abs(value) : 0,
        // }}
        onChange={handleTableChange}
        // style={{ fontSize: "12px !important", overflowX: "scroll" }}
        style={{ fontSize: "10px !important" }}
        //   loading={loadingState}
        rowKey="data"
        key="data"
        scroll={{
          y: "65vh",
          // x: true
        }}
        className="table-css border-current border-white"
        locale={{ emptyText: "No data" }}
        pagination={false}
      />
      {(submatricsDetailsData?.length > 0 && Math.abs(dataCount) > 0) &&
        <div className="pt-5 px-5">
          <div className="text-app-lightgray1 text-xs flex flex-row">
              <div className="flex flex-row items-center">
                <p>{resources.PAGINATION_PAGE_NO}&nbsp;</p> 
                <input type="number" id="pageno" name="pageno" 
                  className="border border-app-lightgray1 rounded-sm w-12 text-center text-sm mr-5"
                  min={1} max={Math.ceil((submatricsDetailsData?.length > 0 ? Math.abs(dataCountExport || dataCount) : 0)/pagination.pageSize)} value={pagination?.current}
                  onChange={(e) => onPageChange(e)}/>
              </div>
              <div className="flex flex-row items-center">
                <p>{resources.PAGINATION_ENTRIES_PER_PAGE}&nbsp;</p> 
                <input type="number" id="entriesperpage" name="entriesperpage"
                  className="border border-app-lightgray1 rounded-sm w-12 text-center text-sm"
                  min={10} max={Math.ceil(submatricsDetailsData?.length > 0 ? Math.abs(dataCountExport || dataCount) : 0)} value={pagination.pageSize} step={10}
                  onChange={(e) => onPerEntryPage(e)}/>
              </div>
          </div>
          <p className="text-10px text-app-lightgray1">{resources.PAGINATION_SHOWING} {pagination?.current} of {Math.ceil((submatricsDetailsData?.length > 0 ? Math.abs(dataCountExport || dataCount) : 0)/pagination.pageSize)}</p>
        </div>
      }
    </div>
  );
};

export default DataTable;
